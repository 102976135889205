import { DataService } from "../axios";


async function addGuest(data){
    return await DataService.post('guest-user/account/',data)
}




export{
    addGuest
}