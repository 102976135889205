<template>
  <div class="memebership-form">
    <div class="step-progress-indicator">
      <div ref="step1-indicator" class="step1-indicator step-indicator-active">
        <span class="step-counter">1</span>
        <div class="step-title">Details</div>
      </div>
      <div>
        <img
          src="@/assets/images/arrow.png"
          style="width: 24px; margin-top: 18px"
          alt="Arrow"
        />
      </div>
      <div ref="step3-indicator" class="step3-indicator">
        <span class="step-counter">2</span>
        <div class="step-title">Interest & Others</div>
      </div>
    </div>
    <div ref="memebrship-step1">
      <CRow>
        <CCol :md="6">
          <CRow>
            <CCol sm="5" md="4" lg="3">
              <div
                class="form-group"
                :class="{ error: v$.data.name.$errors.length }"
              >
                <label for="">Title</label>
                <select class="form-select" v-model="v$.data.title.$model">
                  <option
                    v-for="title in title_list"
                    :value="title.value"
                    :key="title.value"
                    :selected="title.value === 'MR' ? true : false"
                  >
                    {{ title.label }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="7" md="8" lg="9">
              <div
                class="form-group"
                :class="{ error: v$.data.name.$errors.length }"
              >
                <label for="">Name</label>
                <input
                  class="form-control"
                  placeholder="Enter name here!"
                  type="text"
                  v-model="v$.data.name.$model"
                />

                <div class="error-msg" v-if="v$.data.name.$error">
                  Name is required!
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.surname.$errors.length }"
          >
            <label for="">Surname</label>
            <input
              class="form-control"
              placeholder="Surname here!"
              type="text"
              v-model="v$.data.surname.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.data.surname.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>

        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.mobile_phone.$errors.length }"
          >
            <label for="">Mobile Phone</label>
            <input
              class="form-control"
              placeholder="Mobile phone here!"
              type="text"
              v-model="v$.data.mobile_phone.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.mobile_phone.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.email.$errors.length }"
          >
            <label for="">E-mail</label>
            <input
              class="form-control"
              placeholder="Email is here!"
              type="text"
              v-model="v$.data.email.$model"
            />

            <div class="error-msg" v-if="v$.data.email.$error">
              Email is required!
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.dob.$errors.length }"
          >
            <label for="">Date of Birth</label>
            <input
              class="form-control"
              placeholder="Date of Birth here!"
              type="date"
              v-model="v$.data.dob.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.dob.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.house_number.$errors.length }"
          >
            <label for="">House Number</label>
            <input
              class="form-control"
              placeholder="House number here!"
              type="text"
              v-model="v$.data.house_number.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.data.house_number.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.street.$errors.length }"
          >
            <label for="">Street</label>
            <input
              class="form-control"
              placeholder="Street name here!"
              type="text"
              v-model="v$.data.street.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.street.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.street.$errors.length }"
          >
            <label for="">City</label>
            <input
              class="form-control"
              placeholder="Street name here!"
              type="text"
              v-model="v$.data.city.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.city.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.country.$errors.length }"
          >
            <label for="">Country</label>

            <select class="form-select" v-model="v$.data.country.$model">
              <option
                v-for="country in countryList"
                :value="country.code"
                :key="country.code"
                :selected="country.code === 'SA' ? true : false"
              >
                {{ country.name }}
              </option>
            </select>

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.country.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div
            class="form-group"
            :class="{ error: v$.data.street.$errors.length }"
          >
            <label for="post_code">Postcode</label>
            <input
              class="form-control"
              placeholder="Postcode here!"
              type="text"
              v-model="v$.data.postcode.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.postcode.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol :md="6">
          <div class="form-group">
            <label for="">Is VIP ?</label>
            <div style="display: flex; margin-top: 12px" class="is-vip">
              <Toggle v-model="is_vip" />
            </div>
          </div>
        </CCol>
      </CRow>
      <div class="mt-4">
        <div class="subscription-footer" style="flex-direction: row-reverse">
          <CButton class="btn btn-primary" @click="nextStep(3)">Next</CButton>
        </div>
      </div>
    </div>
    <div ref="memebrship-step2">
      <div class="subscription-footer">
        <CButton class="btn btn-primary" @click="prevStep(1)">Back</CButton>
        <CButton class="btn btn-primary" @click="nextStep(3)">Next</CButton>
      </div>
    </div>
    <div ref="memebrship-step3">
      <div>
        <div
          style="
            text-align: center;
            font-size: 15px;
            margin-bottom: 22px;
            margin-top: 64px;
          "
        >
          Please choose user interests
        </div>
        <div class="interest-container">
          <label v-for="interest in hyphen_interest" :key="interest.id">
            <input
              type="checkbox"
              :value="interest.id"
              name="interest_group"
              v-model="v$.data.interests.$model"
              @change="otherInterest($event, interest.label)"
            />
            <div>{{ interest.label }}</div>
          </label>
        </div>
        <div v-if="interest_other_text" class="interest-container-other">
          <div
            class="form-group"
            :class="{ error: v$.data.street.$errors.length }"
          >
            <input
              class="form-control"
              placeholder="Other interest"
              type="text"
              style="height: 40px; font-size: 14px"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.data.postcode.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="text-align: center; font-size: 14px; margin-bottom: 28px">
          How did user hear about hyphen
        </div>
      </div>
      <CRow>
        <CCol md="6" style="margin: auto">
          <div
            class="form-group"
            :class="{ error: v$.data.reached_us.$errors.length }"
          >
            <select
              class="form-select"
              v-model="v$.data.reached_us.$model"
              @change="hereAboutoptionsChange"
            >
              <option
                v-for="option in hereAboutoptions"
                :value="option.value"
                :key="option.value"
                :selected="option.value === 'Internet' ? true : false"
              >
                {{ option.label }}
              </option>
            </select>

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.reached_us.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
        <CCol md="6" v-if="is_hear_from_other">
          <div
            class="form-group"
            :class="{ error: v$.data.reached_us_other.$errors.length }"
          >
            <input
              class="form-control"
              placeholder="How did user hear about hyphen!"
              type="text"
              v-model="v$.data.reached_us_other.$model"
            />

            <div
              class="input-errors"
              v-for="(error, index) of v$.data.reached_us_other.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </CCol>
      </CRow>
      <div class="subscription-footer">
        <CButton class="btn btn-primary" @click="prevStep(1)">Back</CButton>

        <CButton class="btn btn-primary" @click="nextStep(4)">Preview</CButton>
      </div>
    </div>
    <div ref="memebrship-step4">
      <viewPreview
        :user="data"
        :subscription_data="subscription_data"
        :interests="hyphen_interest"
      />
      <div class="subscription-footer" style="margin-top: 64px">
        <CButton class="btn btn-primary" @click="prevStep(3)">Back</CButton>

        <CButton class="btn btn-primary" @click="submitSubscription"
          >Submit</CButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { countryList } from "@/utils/country.js";
import { required, email, minLength } from "@vuelidate/validators";
import { capitalizeFirstLetter } from "@/utils/utility";
import viewPreview from "@/components/member/preview";
import { addGuest } from "@/services/apis/guest";

import {
  hyphenInterestDetails,
  hyphensubscriptionsDetails,
} from "@/services/apis/hyphen";
import Toggle from "@vueform/toggle";

export default {
  components: {
    viewPreview,
    Toggle,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.$refs["memebrship-step2"].style.display = "none";
    this.$refs["memebrship-step3"].style.display = "none";
    this.$refs["memebrship-step4"].style.display = "none";
  },
  created() {
    this.loadHyphenInterestDetails();
    this.loadHyphensubscriptionsDetails();
  },
  data() {
    return {
      is_vip: true,
      countryList: countryList,
      data: {
        name: "",
        surname: "",
        house_number: "",
        street: "",
        country: "SA",
        subscribed: null,
        postcode: "",
        mobile_phone: "",
        email: "",
        reached_us: "Internet",
        title: "MR",
        dob: null,
        interests: [],
        city: "",
        reached_us_other: "",
      },
      hereAboutoptions: [
        { label: "Internet", value: "Internet" },
        { label: "Publication", value: "Publication" },
        { label: "Social Media", value: "Social Media" },
        {
          label: "A friend or Colleague",
          value: "A friend or Colleague",
        },
        { label: "Other", value: "Other" },
      ],
      title_list: [
        { value: "PR", label: "Prince" },
        { value: "DR", label: "Dr" },
        { value: "MR", label: "Mr" },
        { value: "MS", label: "Ms" },
        { value: "MRS", label: "Mrs" },
      ],
      is_hear_from_other: false,
      interest_other_text: false,
      hyphen_interest: [],
      platinum_subscription: [],
      subscription_data: [],

      joint_subscription: [],
      capitalizeFirstLetter: capitalizeFirstLetter,
    };
  },

  validations() {
    return {
      data: {
        name: {
          required,
        },
        title: {},
        surname: {},
        house_number: {},
        street: {},
        country: {},
        postcode: {},
        mobile_phone: {
          required,
        },
        email: {
          required,
          email,
        },
        city: {},
        subscribed: {},
        reached_us: {},
        reached_us_other: {},
        dob: {},
        interests: {},
      },
    };
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        console.log("Form is invalid!");
      } else {
        this.nextStep();
      }
    },
    nextStep(step) {
      switch (step) {
        case 2:
          this.v$.$touch();
          if (this.v$.$invalid) {
            // console.log(this.v$)
          } else {
            this.$refs["memebrship-step1"].style.display = "none";
            this.$refs["memebrship-step2"].style.display = "block";
            this.$refs["step1-indicator"].classList.remove(
              "step-indicator-active"
            );
     
          }
          break;
        case 3:
          this.v$.$touch();
          if (this.v$.$invalid) {
            // console.log(this.v$)
          } else {
            this.$refs["memebrship-step1"].style.display = "none";
            this.$refs["memebrship-step2"].style.display = "none";
            this.$refs["memebrship-step3"].style.display = "block";
            this.$refs["step1-indicator"].classList.remove(
              "step-indicator-active"
            );

            this.$refs["step3-indicator"].classList.add(
              "step-indicator-active"
            );
          }

          break;
        case 4:
          this.$refs["memebrship-step1"].style.display = "none";
          this.$refs["memebrship-step2"].style.display = "none";
          this.$refs["memebrship-step3"].style.display = "none";
          this.$refs["memebrship-step4"].style.display = "block";
          this.$refs["step1-indicator"].classList.remove(
            "step-indicator-active"
          );
          this.$refs["step1-indicator"].classList.remove(
            "step-indicator-active"
          );

          this.$refs["step3-indicator"].classList.remove(
            "step-indicator-active"
          );
          break;
        default:
          console.log("Invalid step!");
          break;
      }
    },
    prevStep(step) {
      switch (step) {
        case 1:
          this.$refs["memebrship-step1"].style.display = "block";
          this.$refs["memebrship-step2"].style.display = "none";
          this.$refs["memebrship-step3"].style.display = "none";
          this.$refs["memebrship-step4"].style.display = "none";
 
          this.$refs["step1-indicator"].classList.add("step-indicator-active");
          break;
        case 2:
          this.$refs["memebrship-step1"].style.display = "none";
          this.$refs["memebrship-step3"].style.display = "none";
          this.$refs["memebrship-step2"].style.display = "block";
          this.$refs["memebrship-step4"].style.display = "none";

          this.$refs["step3-indicator"].classList.remove(
            "step-indicator-active"
          );

          break;
        case 3:
          this.$refs["memebrship-step1"].style.display = "none";
          this.$refs["memebrship-step2"].style.display = "none";
          this.$refs["memebrship-step4"].style.display = "none";
          this.$refs["memebrship-step3"].style.display = "block";
          this.$refs["step3-indicator"].classList.add("step-indicator-active");
          break;
        default:
          console.log("Invalid step!");
          break;
      }
    },
    hereAboutoptionsChange() {
      console.log(this.data.reached_us == "Other");
      this.data.reached_us == "Other"
        ? (this.is_hear_from_other = true)
        : (this.is_hear_from_other = false);
    },
    otherInterest(event, label) {
      if (label == "OTHERS") {
        this.interest_other_text = event.target.checked;
      }
    },
    loadHyphenInterestDetails() {
      hyphenInterestDetails().then((res) => {
        this.hyphen_interest = res.data;
      });
    },
    loadHyphensubscriptionsDetails() {
      hyphensubscriptionsDetails().then((res) => {
        this.subscription_data = res.data;
        this.platinum_subscription = res.data.filter(
          (item) => item.membership_type.key === "platinum"
        );
        this.joint_subscription = res.data.filter(
          (item) => item.membership_type.key === "joint"
        );
      });
    },
    vipStatusChanged(val) {
      console.log(val);
    },
    submitSubscription() {
      let data = { ...this.data };
      data.membership_status = 3;
      this.is_vip ? (data.circle = 1) : (data.circle = 2);
      addGuest(data)
        .then(() => {
          this.$Swal
            .fire("User added", "New member is added to hyphen.", "success")
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Subscription" });
              }
            });
        })
        .catch((err) => {
          let errors = "";
          for (const [key, value] of Object.entries(err.response.data)) {
            for (let i = 0; i < value.length; i++) {
              errors += key + ":" + value[i] + ", ";
            }
          }
          this.$Swal
            .fire(
              "Failed",
              `<span style="color:red">${errors.slice(0, -3) + "!"}</span>`,
              "danger"
            )
            .then((result) => {});
        });
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css">
</style>
<style>
.toggle-on {
  background: black !important ;
  border-color: black !important;
}
.toggle-container:focus {
  border: none !important;
}
.memebership-form {
  padding-bottom: 24px;
}
.memebership-form .error-msg {
  color: red;
  margin-top: 4px;
  margin-left: 2px;
  font-size: 0.92rem;
}
.memebership-form label {
  margin-top: 14px;
  font-size: 0.9rem;
  color: gray;
}
.step-progress-indicator {
  width: 220px;
  margin: auto;
  margin-bottom: 48px;
  justify-content: space-between;
  display: flex;
}
.step1-indicator,
.step2-indicator,
.step3-indicator {
  background-color: #c5a58f;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
}
.step-indicator-active {
  background-color: black;
}
.step-counter {
  color: black;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  width: 48px;
  height: 48px;
  padding-top: 12px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  margin-top: 8px;
  margin-left: -24px;
}
.step-title {
  margin-top: 68px;
  font-size: 12px;
  line-height: 14px;
}
.subscription-container {
  min-height: 100px;
  background-color: #fafafa;
  position: relative;
  border-radius: 12px;
  margin-top: 14px;
  margin-bottom: 24px;
  padding: 28px 18px;
}
.subscription-label {
  position: absolute;
  top: -14px;
  background-color: #000;
  color: #c5a58f;
  padding: 8px 38px;
  border-radius: 8px;
  left: 16px;
  font-size: 12px;
  width: 160px;
  text-align: center;
}
.subscription-label img {
  width: 18px;
  margin-right: 10px;
}
.subscription-item {
  background-color: #fff;
  padding: 12px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 14px;
}
.subscription-category {
  padding: 8px 14px;
  border-radius: 12px;
  margin-right: 4px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  height: 120px;
}
.subscription-category span {
  font-weight: 500;
}
.subscription-category:hover {
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 35%);
  -webkit-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 35%);
  -moz-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 35%);
}

.subscription-category-container input[type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}

.subscription-category-container input[type="radio"]:checked + div {
  background-color: #f7e7dc !important;
  color: #000;
}
.interest-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #fafafa;
  min-height: 100px;
  border-radius: 12px;
  margin-top: 14px;
  margin-bottom: 24px;
  padding: 28px 18px;
}
.interest-container div {
  width: 240px;
  padding: 8px 14px;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 25%);
}

.interest-container input[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  display: none;
}

.interest-container input[type="checkbox"]:checked + div {
  background-color: #f7e7dc !important;
  color: #000;
}
.interest-container-other {
  background-color: #fafafa;
  padding: 18px;
  border-radius: 12px;
  margin-top: -38px;
}
.interest-container-other input {
  width: 278px;
  margin: auto;
  display: block;
}
.subscription-title {
  font-weight: 500;
}
.subscription-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top:18px;
}
.subscription-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 44px;
  padding-top: 18px;
  border-radius: 12px;
  margin-bottom: 24px;
}
.subscription-footer button {
  width: 120px;
}
.is-vip label {
  margin-top: 0px !important;
  font-size: 0.9rem;
  color: gray;
  margin-right: 18px;
}
</style>
