<template>
  <div v-if="user" style="font-size:14px">
      <div class="subscription-preview">Preview</div>
     <div class="row">
      <div class="col-4">Name</div>
      <div class="col-8">
        {{ user.title }}. {{ user.name }}
      </div>
    </div>
    <div class="row" v-if="currentSubscription">
      <div class="col-4">Subscription Details</div>
      <div class="col-8 address-container">
        <div class="row">
          <div class="col-4">Title</div>
          <div class="col-8">
            {{
              currentSubscription &&
              currentSubscription.title
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Max. People</div>
          <div class="col-8">
            {{
              currentSubscription &&
              currentSubscription.number_of_people
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Type</div>
          <div class="col-8">
            {{
              capitalizeFirstLetter(
               currentSubscription &&
               currentSubscription.subscription_type
              )
            }}
          </div>
        </div>
      </div>
    </div> 
     <div class="row">
      <div class="col-4">Surname</div>
      <div class="col-8">{{ user.surname }}</div>
    </div>
    <div class="row">
      <div class="col-4">Date of Birth</div>
      <div class="col-8">
        {{ $moment(Date(user.dob)).format("ll") }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">Contact</div>
      <div class="col-8 contact-container">
        <div class="row">
          <div class="col-4">Email</div>
          <div class="col-8">
            {{ user.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Phone</div>
          <div class="col-8">
            {{ user.mobile_phone }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">Address</div>
      <div class="col-8 address-container">
        <div class="row">
          <div class="col-4">Street</div>
          <div class="col-8">
            {{ user.street }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">House Number</div>
          <div class="col-8">
            {{ user.house_number }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">City</div>
          <div class="col-8">
            {{ user.city }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Country</div>
          <div class="col-8">
            {{ user.country }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">Postcode</div>
          <div class="col-8">
            {{ user.postcode }}
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-4">Interest</div>
      <div class="col-8 interest-continer">
        <div
          v-for="interest in selectedInterests"
          :key="interest.id"
        >
          {{ interest.label }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">Reached Us</div>
      <div class="col-8">
        {{ user.reached_us =='Other'?user.reached_us_other:user.reached_us }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">Created At</div>
      <div class="col-8">
        {{ $moment(Date(user.created)).format("lll") }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">Updated At</div>
      <div class="col-8">
        {{ $moment(Date(user.modified)).format("lll") }}
      </div>
    </div> 

  </div>
</template>

<script>
import { capitalizeFirstLetter } from "@/utils/utility";

export default {
    props:["user","subscription_data","interests"],
    data(){
        return{
            capitalizeFirstLetter :capitalizeFirstLetter 
        }

    },
    computed:{
        currentSubscription(){
            if(this.user && this.subscription_data){
                return this.subscription_data.find(item=>item.id===this.user.subscribed)
            }
            else{
                return null;
            }
        },
        selectedInterests(){
            if(this.interests&&this.interests.length&&this.subscription_data){
                return this.interests.filter(item=>{
                    if(item.id in this.user.interests){
                        return item;
                    }
                     
                })
            }
            else{
                return []
            }
        }   
    }
};
</script>

<style scoped>
.interest-continer {
  display: flex;
  flex-wrap: wrap;
}
.interest-continer div {
  background-color: #000;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #c8a791;
  padding: 2px 18px;
}
.row {
  margin-bottom: 12px;
}
.modal-custom-st {
  padding: 0px 64px;
  font-size: 15px;
}
.col-4 {
  color: gray;
}
.memebrship-status {
  text-align: center;
  margin-bottom: 32px;
  color: #c8a791;
  padding: 18px 12px;
  font-size: 17px;
}
.address-container,
.contact-container {
  background-color: #fafafa;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 0.87rem;
}
.subscription-preview{
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    background-color: #000;
    width:fit-content;
    margin:auto;
    margin-bottom: 28px;
    padding: 4px 16px;
    border-radius: 8px;
    color: #c8a791;

}
</style>